import React from 'react';

import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import CallMergeIcon from '@material-ui/icons/CallMerge';

const menuItems = [
	{
		id: 1,
		target: '/',
		label: 'Home',
		auth: false,
		top: false,
		bottom: false,
		icon: <HomeIcon />,
	},
	{
		id: 2,
		target: '/',
		label: 'Transformatie',
		auth: false,
		top: true,
		bottom: true,
		icon: <ImportantDevicesIcon />,
	},
	{
		id: 3,
		target: '/',
		label: 'Digitalisering',
		auth: false,
		top: true,
		bottom: true,
		icon: <LaptopChromebookIcon />,
	},
	{
		id: 4,
		target: '/',
		label: 'Automatisering',
		auth: false,
		top: true,
		bottom: true,
		icon: <CallMergeIcon />,
	},
	{
		id: 5,
		target: '/',
		label: 'Dashboarding',
		auth: false,
		top: true,
		bottom: true,
		icon: <AssessmentIcon />,
	},
	{
		id: 6,
		target: '/auth',
		label: 'Klant portaal',
		auth: true,
		top: false,
		bottom: false,
		icon: <ExitToAppIcon />,
	},
];

export default menuItems;
