import React from 'react';
import { forwardRef } from 'react';

import styled from 'styled-components';

const Activity = styled.div`
	display: flex;
	width: 100%;
`;

const ActivityInformation = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 40px;
`;

const ActivitySubtitle = styled.h3`
	margin: 0 0;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.small};
`;

const ActivityText = styled.p`
	padding: 0 0 20px 0;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.regular};
	font-size: ${(props) => props.theme.fontSize.small};
	line-height: 2;
`;

const ActivitySkills = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 40px;
`;

const ActivitySkillButton = styled.span`
	text-align: center;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.tiny};
	background-color: ${(props) => props.theme.buttonColor.primary};
	margin: 0 20px 0 0;
	padding: 5px 10px;
	border-radius: 3px;
	min-width: 50px;
`;

const Period = styled.div`
	min-width: 170px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.regular};
	font-size: ${(props) => props.theme.fontSize.small};

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		display: none;
	}
`;

const MarkerProject = styled.span`
	margin-top: 5px;
	margin-left: 170px;
	height: 12px;
	width: 12px;
	background-color: ${(props) => props.theme.backgroundColor.secondaryFar};
	border-radius: 50%;
	display: inline-block;
	position: absolute;

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		margin-left: 0;
	}
`;

const ActivityProject = forwardRef(({ title, description, skills }, ref) => {
	return (
		<Activity ref={ref}>
			<Period />
			<MarkerProject />
			<ActivityInformation>
				<ActivitySubtitle>{title}</ActivitySubtitle>
				<ActivityText>{description}</ActivityText>
				<ActivitySkills>
					{skills.map((skill) => (
						<ActivitySkillButton>{skill}</ActivitySkillButton>
					))}
				</ActivitySkills>
			</ActivityInformation>
		</Activity>
	);
});

export default ActivityProject;
