import React from 'react';

/* Backend Functionality */

import { Switch, Route } from 'react-router-dom';

/* Style Components */
import { StylesProvider } from '@material-ui/core/styles';

/* Pages */

import HomePage from './pages/home';
import ResumePage from './pages/resume';

function App() {
	return (
		<StylesProvider injectFirst>
			<Switch>
				<Route exact path='/' component={HomePage} />
				<Route exact path='/cv' component={ResumePage} />
				<Route exact path='/cv/marcklootwijk' component={ResumePage} />
			</Switch>
		</StylesProvider>
	);
}

export default App;
