import React from 'react';

import menuItems from '../../utils/menu';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

/* Style Components */
import styled from 'styled-components';

const Container = styled(AppBar)`
	transition: all 0.2s ease-in-out;
	z-index: 2;
	display: flex;
	align-items: center;
	background-color: ${(props) =>
		props.visible ? props.theme.backgroundColor.secondary : 'transparent'};
	box-shadow: ${(props) =>
		props.visible
			? '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
			: '0px 0px 0px 0px rgba(0,0,0,0.2)'};
`;

const Content = styled(Toolbar)`
	max-width: ${(props) => props.theme.innerWidth};
	width: 100%;
	height: ${(props) => (props.height ? props.height : '64px')};
	padding: 0 20px;
`;

const Logo = styled(Typography)`
	display: flex;
	cursor: pointer;
	flex-grow: 1;
	min-width: 150px;
	color: ${(props) =>
		props.visible
			? props.theme.color.primary
			: props.theme.color.contrastLight};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.huge};
`;

const MenuButton = styled(Button)`
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	color: ${(props) =>
		props.visible
			? props.theme.color.primary
			: props.theme.color.contrastLight};
	padding: 5px 20px;

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		display: none;
	}
`;

const MenuSpacer = styled.div`
	width: 20px;
`;

export default function Header({ forwardedRefs, height, includeAuth }) {
	const triggerSlide = useScrollTrigger({
		disableHysteresis: true,
		threshold: 250,
	});

	const triggerFade = useScrollTrigger({
		disableHysteresis: true,
		threshold: 250,
	});

	const StringToAnchor = (label) => {
		switch (label) {
			case 'Transformatie':
				return forwardedRefs.transformation.current;
			case 'Digitalisering':
				return forwardedRefs.digital.current;
			case 'Automatisering':
				return forwardedRefs.automate.current;
			case 'Dashboarding':
				return forwardedRefs.dashboard.current;
			case 'Cover':
				return forwardedRefs.cover.current;
			default:
				return null;
		}
	};

	const handleClick = (target) => {
		if (target) {
			target.scrollIntoView({
				behavior: 'smooth',
				alignToTop: true,
			});
		}
	};

	return (
		<React.Fragment>
			<Fade in={!triggerFade} timeout={400}>
				<Container position='fixed' visible={0}>
					<Content height={height}>
						<MenuSpacer />
						<Logo visible={0}>ESC Online</Logo>
						{menuItems
							.filter((item) => item.top === true)
							.filter((item) => item.auth === false)
							.map((item) => (
								<MenuButton
									onClick={(e) => handleClick(StringToAnchor(item.label))}
									visible={0}
									key={item.id}
								>
									{item.label}
								</MenuButton>
							))}
						{includeAuth &&
							menuItems
								.filter((item) => item.top === true)
								.filter((item) => item.auth === true)
								.map((item) => (
									<MenuButton
										onClick={(e) => handleClick(StringToAnchor(item.label))}
										visible={0}
										key={item.id}
									>
										{item.label}
									</MenuButton>
								))}
						<MenuSpacer />
					</Content>
				</Container>
			</Fade>
			<Slide in={triggerSlide} timeout={400}>
				<Container position='fixed' visible={1}>
					<Content height={height}>
						<MenuSpacer />
						<Logo
							onClick={(e) => handleClick(StringToAnchor('Cover'))}
							visible={1}
						>
							ESC Online
						</Logo>
						{menuItems
							.filter((item) => item.top === true)
							.filter((item) => item.auth === false)
							.map((item) => (
								<MenuButton
									onClick={(e) => handleClick(StringToAnchor(item.label))}
									visible={1}
									key={item.id}
								>
									{item.label}
								</MenuButton>
							))}
						{includeAuth &&
							menuItems
								.filter((item) => item.top === true)
								.filter((item) => item.auth === true)
								.map((item) => (
									<MenuButton
										onClick={(e) => handleClick(StringToAnchor(item.label))}
										visible={1}
										key={item.id}
									>
										{item.label}
									</MenuButton>
								))}
						<MenuSpacer />
					</Content>
				</Container>
			</Slide>
		</React.Fragment>
	);
}
