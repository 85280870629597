import React from 'react';

import { Link } from 'react-router-dom';

/* Style Components */
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const Cover = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: ${(props) => props.theme.backgroundColor.primary};
`;

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-width: ${(props) => props.theme.innerWidth};
	width: 100%;
	padding: 20px 0;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 20px 40px 20px 20px;
	min-width: 300px;
	padding: 20px 20px;
`;

const Title = styled(Typography)`
	color: ${(props) => props.theme.color.contrastLight};
	padding-bottom: 15px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.large};
`;

const Text = styled(Typography)`
	text-align: justify;
	color: ${(props) => props.theme.color.tertiary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.tiny};
	line-height: 2;
`;

const Href = styled(Link)`
	text-decoration: none;
	color: ${(props) => props.theme.color.tertiary};
	transition: all 0.1s ease-in-out;

	&:hover {
		color: ${(props) => props.theme.color.contrastLight};
	}
`;

const Ul = styled(List)`
	padding-top: 0;
	margin-top: -10px;
`;

const Li = styled(ListItem)`
	padding-top: 8px;
	padding-left: 0;
	padding-bottom: 0;
	transition: all 0.2s ease-in-out;

	&:hover {
		padding-left: 12px;
	}
`;

const ItemText = styled(ListItemText)`
	& .MuiTypography-body1 {
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-size: ${(props) => props.theme.fontSize.tiny};
		font-weight: ${(props) => props.theme.fontWeight.medium};
	}
`;

export default function Footer() {
	return (
		<Cover>
			<Container>
				<Content>
					<Title>ESC Online</Title>
					<Text>
						Digitalisering van bedrijfsactiviteiten en ontwikkelaar op het
						gebied van automatisering en dashboarding. Ondersteunt uw
						onderneming bij het inzetten van technologie en data ten behoeve van
						de digitale transformatie.
					</Text>
				</Content>
				<Content>
					<Title>Bedrijfsinformatie</Title>
					<Text>Email: info@esconline.nl</Text>
					<Text>KvK: 78190037</Text>
					<Text>BTW: NL003298148B02</Text>
				</Content>
			</Container>
		</Cover>
	);
}
