import React from 'react';

/* Style Components */
import styled from 'styled-components';

const Cover = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	background-color: ${(props) =>
		props.color === 'even'
			? props.theme.backgroundColor.secondary
			: props.theme.backgroundColor.secondaryClose};
`;

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-width: ${(props) => props.theme.innerWidth};
	width: 100%;
	padding: 60px 0 60px 0;

	${(props) =>
		props.img &&
		`background-image: url(${props.img});
		background-repeat: no-repeat;
		background-size: auto;
		background-position: right;`};

	${(props) =>
		props.align &&
		`justify-content: ${props.align}; background-position: left;`};

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		background-size: 0 0;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 20px;

	${(props) => (props.fullWidth ? `flex: 1` : `flex: 0.5`)};

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		flex: 1;
	}
`;

function Services({ children, img, align, color, fullWidth }) {
	return (
		<Cover color={color}>
			<Container img={img} align={align}>
				<Content fullWidth={fullWidth}>{children}</Content>
			</Container>
		</Cover>
	);
}

export default Services;
