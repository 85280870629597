import React, { useRef } from 'react';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Cover from '../components/Landing/Cover';

import ServicesTransformation from '../components/Landing/ServicesTransformation';
import ServicesDigital from '../components/Landing/ServicesDigital';
import ServicesAutomate from '../components/Landing/ServicesAutomate';
import ServicesDashboard from '../components/Landing/ServicesDashboard';

import ScrollTop from '../components/ScrollTop/ScrollTop';
import BottomNav from '../components/BottomNavigation/BottomNavigation';

import styled from 'styled-components';

const ContainerTop = styled.div`
	position: relative;
	z-index: 0;
	width: 100%;
	height: 800px;
`;

function HomePage() {
	const anchorCover = useRef(null);

	const anchorTransformation = useRef(null);
	const anchorDigital = useRef(null);
	const anchorAutomate = useRef(null);
	const anchorDashboard = useRef(null);

	const refs = {
		transformation: anchorTransformation,
		digital: anchorDigital,
		automate: anchorAutomate,
		dashboard: anchorDashboard,
		cover: anchorCover,
	};

	return (
		<React.Fragment>
			<ContainerTop>
				<Header forwardedRefs={refs} />
				<Cover ref={anchorCover} height='800px' img={'/cover.png'} />
			</ContainerTop>
			<ServicesTransformation
				img={'/illustration_transformation.svg'}
				ref={anchorTransformation}
			/>
			<ServicesDigital
				img={'/illustration_digital.svg'}
				ref={anchorDigital}
				color='even'
				align='flex-end'
			/>
			<ServicesAutomate
				img={'/illustration_automate.svg'}
				ref={anchorAutomate}
			/>
			<ServicesDashboard
				img={'/illustration_dashboard.svg'}
				ref={anchorDashboard}
				color='even'
				align='flex-end'
			/>
			<Footer />
			<ScrollTop el={anchorCover} />
			<BottomNav forwardedRefs={refs} />
		</React.Fragment>
	);
}

export default HomePage;
