import React, { forwardRef } from 'react';

import Typography from '@material-ui/core/Typography';

/* Style Components */
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	background-image: url(${(props) => props.img});
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	height: ${(props) => props.height};
	width: 100%;

	&:before {
		z-index: 0;
		content: '';
		background-color: ${(props) => props.theme.backgroundColor.primary};
		opacity: 0.6;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	z-index: 1;
	max-width: ${(props) => props.theme.innerWidth};
	width: 100%;
`;

const TitleContainer = styled.p`
	padding: 8px 0 2px 0;
	margin: 8px 40px;
`;

const Title = styled.span`
	transition: all 0.5s ease-in-out;
	background: ${(props) => props.theme.color.primary};
	box-shadow: 15px 0 0 ${(props) => props.theme.color.primary},
		-15px 0 0 ${(props) => props.theme.color.primary};
	width: fit-content;
	padding: 16px 0;
	color: ${(props) => props.theme.color.contrastLight};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.title};
	margin-bottom: 20px;
	vertical-align: top;
	line-height: 2;
`;

const Subtitle = styled.span`
	transition: all 0.5s ease-in-out;
	padding: 8px 0;
	line-height: 2;
	background: ${(props) => props.theme.color.primary};
	box-shadow: 15px 0 0 ${(props) => props.theme.color.primary},
		-15px 0 0 ${(props) => props.theme.color.primary};
	width: fit-content;
	color: ${(props) => props.theme.color.contrastLight};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.huge};
`;

function Cover({ height, img }, ref) {
	return (
		<Container ref={ref} height={height} img={img}>
			<Content>
				<TitleContainer>
					<Title>Op weg naar de digitale onderneming</Title>
				</TitleContainer>
				<TitleContainer>
					<Subtitle>
						Verbeter bedrijfsprestaties met technologie en data
					</Subtitle>
				</TitleContainer>
			</Content>
		</Container>
	);
}

const forwardedCover = forwardRef(Cover);

export default forwardedCover;
