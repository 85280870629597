import React from 'react';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Fab from '@material-ui/core/Fab';

/* Style Components */
import styled from 'styled-components';

const Container = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;

	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		display: none;
	}
`;

const Button = styled(Fab)`
	background-color: ${(props) => props.theme.backgroundColor.secondaryFar};
`;

const Arrow = styled(KeyboardArrowUpIcon)`
	color: ${(props) => props.theme.color.contrastLight};
`;

export default function ScrollTop({ el }) {
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event) => {
		if (el.current) {
			el.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	};

	return (
		<Zoom in={trigger}>
			<Container role='presentation'>
				<Button
					onClick={handleClick}
					size='small'
					aria-label='scroll back to top'
				>
					<Arrow />
				</Button>
			</Container>
		</Zoom>
	);
}
