import React, { forwardRef } from 'react';

import styled from 'styled-components';

const Activity = styled.div`
	display: flex;
	width: 100%;
`;

const ActivityInformation = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 40px;
`;

const ActivityTitle = styled.h2`
	margin: 0 0;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.large};
	padding-bottom: 5px;
`;

const ActivitySubtitle = styled.h3`
	margin: 0 0;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.small};
`;

const ActivityText = styled.p`
	padding: 0 0 20px 0;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.regular};
	font-size: ${(props) => props.theme.fontSize.small};
	line-height: 2;
`;

const Period = styled.div`
	min-width: 170px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.regular};
	font-size: ${(props) => props.theme.fontSize.small};

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		display: none;
	}
`;

const MarkerCompany = styled.span`
	margin-top: 5px;
	margin-left: 170px;
	height: 15px;
	width: 15px;
	background-color: ${(props) => props.theme.backgroundColor.primary};
	border-radius: 50%;
	display: inline-block;
	position: absolute;

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		margin-left: 0;
	}
`;

const ActivityCompany = forwardRef(
	({ startDate, endDate, company, position, description }, ref) => {
		return (
			<Activity ref={ref}>
				<Period>
					{startDate} - {endDate}
				</Period>
				<MarkerCompany />
				<ActivityInformation>
					<ActivityTitle>{company}</ActivityTitle>
					<ActivitySubtitle>{position}</ActivitySubtitle>
					<ActivityText>{description}</ActivityText>
				</ActivityInformation>
			</Activity>
		);
	}
);

export default ActivityCompany;
