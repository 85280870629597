import React, { forwardRef } from 'react';

import Services from './Services';
import Typography from '@material-ui/core/Typography';

/* Style Components */
import styled from 'styled-components';

const Title = styled(Typography)`
	padding-bottom: 10px;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.huge};
`;

const Text = styled(Typography)`
	text-align: justify;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.regular};
`;

const ScrollFiller = styled.div`
	padding: 20px 0;
	background-color: ${(props) =>
		props.color === 'even'
			? props.theme.backgroundColor.secondary
			: props.theme.backgroundColor.secondaryClose};
`;

const ServicesTransformation = ({ img, align, color, fullWidth }, ref) => {
	return (
		<ScrollFiller ref={ref} color={color}>
			<Services img={img} align={align} color={color} fullWidth={fullWidth}>
				<Title ref={ref}>Digitale transformatie</Title>
				<Text>
					Bedrijven staan tegenwoordig voor een grote uitdaging. In toenemende
					mate worden technologie en data ingezet om bedrijfsprocessen te
					optimaliseren en te automatiseren. Ook de bedrijfsvoering ondervindt
					de impact van digitalisering. Belangrijke beslissingen zijn in
					toenemende mate data-gedreven en managementinformatie is real-time
					beschikbaar.
				</Text>
				<Text>
					<br />
				</Text>
				<Text>
					Digitale transformatie begint vaak bij de <b>digitalisering</b> van
					bedrijfsprocessen. Informatie moet immers digitaal beschikbaar zijn om
					er vervolgens mee aan de slag te kunnen. Als processen digitaal
					verlopen is het mogelijk om deze te automatiseren. Hierbij is het zaak
					om processen goed onder de loep nemen. De <b>automatisering</b> van
					een slecht lopend proces biedt vaak niet de gewenste resultaten.
					Vervolgens kan de groeiende datavoorziening ingezet worden om
					inzichten te bieden. Met behulp van <b>dashboarding</b> en rapportages
					kan informatie door de juiste mensen in uw organisatie worden
					ingezien.
				</Text>
				<Text>
					<br />
				</Text>
				<Text>
					ESC Online staat ondernemingen bij in hun digitale transformatie. Door
					een brede kennis van technologie zijn wij in staat om processen te
					digitaliseren, automatiseren en informatie effectief te presenteren.
					Door ervaring op al deze vlakken overzien wij het speelveld en zijn
					wij in staat duurzame oplossingen te realiseren.
				</Text>
			</Services>
		</ScrollFiller>
	);
};

const forwardServicesTransformation = forwardRef(ServicesTransformation);

export default forwardServicesTransformation;
