import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
	backgroundColor: {
		secondary: '#ffffff',
		secondaryFar: '#ff5a5f',
		secondaryClose: '#f7f7f7',
		primary: '#324359',
		primaryDark: '#3D4756',
		primaryLight: '#0097FA',
		tertiary: '#9EAFCA',
	},
	color: {
		primary: '#324359',
		secondary: '#0097FA',
		tertiary: '#A1ACBD',
		quartiary: '#8a99b3',
		contrastLight: '#ffffff',
		contrastPrimary: '#ff8588',
	},
	buttonColor: {
		primary: '#D7D7D7',
	},
	fontFamily: {
		primary: 'Exo',
	},
	fontSize: {
		mini: '10px',
		tiny: '14px',
		small: '16px',
		medium: '18px',
		large: '20px',
		huge: '24px',
		title: '30px',
		scream: '48px',
	},
	fontWeight: {
		extrabold: 800,
		bold: 700,
		semibold: 600,
		medium: 500,
		regular: 400,
		light: 300,
		extralight: 200,
		thin: 100,
	},
	breakpoint: {
		xs: '480px',
		sm: '768px',
		md: '992px',
		lg: '1200px',
	},
	innerWidth: '1120px',
};

const Theme = ({ children }) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
