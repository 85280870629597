import React, { useState, useEffect } from 'react';

import ActivityCompany from '../components/Resume/ActivityCompany';
import ActivityProject from '../components/Resume/ActivityProject';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import FlipMove from 'react-flip-move';

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: ${(props) => props.theme.innerWidth};
	align-items: left;
	padding: 20px;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		width: calc(100% - 20px);
	}
`;

const HeaderTitle = styled.h2`
	margin: 5px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.huge};

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
	}
`;

const HeaderSubtitle = styled.h3`
	margin: 5px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.medium};

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
	}
`;

const Profile = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 340px;
	background-color: ${(props) => props.theme.backgroundColor.primary};

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		height: 10px;
	}
`;

const ProfileInner = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: ${(props) => props.theme.innerWidth};
`;

const ProfileInformation = styled.div`
	display: flex;
	flex: 2;
	flex-direction: column;
	max-width: 600px;
	padding: 20px 40px 20px 0;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
	}

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		display: none;
	}
`;

const ProfileTitle = styled.h2`
	text-transform: uppercase;
	margin: 5px;
	padding-bottom: 10px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.small};
	color: white;
`;

const ProfileText = styled.p`
	margin: 5px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.regular};
	font-size: ${(props) => props.theme.fontSize.small};
	line-height: 2;
	color: white;
`;

const ProfilePhoto = styled.div`
	display: flex;
	position: relative;
	flex: 1;
	flex-direction: column;
	height: 100%;

	@media (max-width: ${(props) => props.theme.breakpoint.md}) {
		display: none;
	}
`;

const ProfileImage = styled.div`
	display: flex;
	width: 100%;
	height: 340px;
	background-image: url(${(props) => props.img});
	background-size: cover;
	background-position: center;
`;

const SkillSelect = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: ${(props) => props.theme.innerWidth};

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
		width: calc(100% - 40px);
	}
`;

const SelectText = styled.h3`
	display: flex;
	align-items: center;
	min-width: 170px;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.small};
`;

const SelectIcon = styled(SearchRoundedIcon)`
	padding: 0 10px 0 0;
`;

const SelectBar = styled(Select)`
	width: 100%;
	margin: 40px 0;
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.small};
	&:focus {
		border-color: ${(props) => props.theme.backgroundColor.primary};
	}
`;

const Experience = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: ${(props) => props.theme.innerWidth};
	padding-top: 20px;

	@media (max-width: ${(props) => props.theme.breakpoint.lg}) {
		padding-left: 20px;
		width: calc(100% - 40px);
	}
`;

const ActivityContainer = styled.div``;

const animatedComponents = makeAnimated();

function ResumePage() {
	const skills = [
		{ value: 'Power BI', label: 'Power BI' },
		{ value: 'Power Query', label: 'Power Query' },
		{ value: 'DAX', label: 'DAX' },
		{ value: 'M', label: 'M' },
		{ value: 'Excel', label: 'Excel' },
		{ value: 'VBA', label: 'VBA' },
		{ value: 'SQL', label: 'SQL' },
		{ value: 'R', label: 'R' },
		{ value: 'Python', label: 'Python' },
		{ value: 'RPA', label: 'RPA' },
		{ value: 'HTML', label: 'HTML' },
		{ value: 'CSS', label: 'CSS' },
		{ value: 'React', label: 'React' },
		{ value: 'PHP', label: 'PHP' },
	];

	const experiences = [
		{
			id: 'a1',
			from: 'jun 2020',
			until: 'huidig',
			company: 'ESC Online',
			position: 'Eigenaar & Ontwikkelaar',
			description:
				'Digitalisering van bedrijfsactiviteiten en adviseur op het gebied van managementinformatie en algehele IT-vraagstukken.',
			projects: [
				{
					id: 'p1',
					title: 'Reporting: Automatische sales rapportages',
					description:
						'Automatiseren en opstellen van diverse wekelijkse rapportages inclusief opzetten van een data model in dit kader.',
					skills: ['Power Query', 'DAX', 'M', 'Excel'],
				},
				{
					id: 'p2',
					title: 'CI/CD: High-availability server',
					description:
						'Opzetten van OTAP-ontwikkelstraat en high-availability server, inclusief versiebeheer middels Git (Continuous Integration) en automatische uitrol van updates (Continuous Deployment).',
					skills: ['Ubuntu', 'SQL', 'PHP'],
				},
			],
		},
		{
			id: 'a2',
			from: 'jul 2018',
			until: 'mei 2020',
			company: 'BDO Advisory B.V.',
			position: 'Senior Consultant Data Analytics',
			description:
				'Opdrachten uitgevoerd bij klanten op het gebied van data-analyse, dashboarding, digitalisering en automatisering. Tevens ingeschakeld bij projecten in het kader van project- en stakeholder management.',
			projects: [
				{
					id: 'p3',
					title: 'Dashboarding: Standaard rapportage M&A',
					description:
						'Opzetten van een geautomatiseerde pipeline voor het verwerken van bedrijfsgegevens alsmede het ontwikkelen van dashboards in dit kader.',
					skills: ['Power BI', 'Power Query', 'DAX', 'M', 'Excel', 'Python'],
				},
				{
					id: 'p4',
					title: 'Monitoring: financiële transacties',
					description:
						'In het kader van de interne audit verantwoordelijk voor het opzetten van forensische financiële monitoring.',
					skills: ['Power BI', 'Python', 'SQL'],
				},
				{
					id: 'p5',
					title: 'Reporting: Regulatory reporting bancaire sector',
					description:
						'Als projectmanager en ontwikkelaar betrokken bij de bouw van een financiële data mart, alsmede de ontwikkeling van diverse financiële rapportages.',
					skills: ['SQL', 'Data-warehousing'],
				},
				{
					id: 'p6',
					title: 'Automatisering: banking audit',
					description:
						'Ontwikkeling van controlesysteem ten behoeve van de audit in de financiële sector. Voornamelijk controles op EBA-regels voor COREP en FINREP.',
					skills: ['Python', 'SQL'],
				},
				{
					id: 'p7',
					title: 'Automatisering: crediteurenadministratie',
					description:
						'Ontwikkeling en implementatie van een virtuele robot om crediteuren aan te maken op basis van factuurherkenning.',
					skills: ['RPA', 'BPM', 'UiPath'],
				},
				{
					id: 'p8',
					title: 'Data obfuscatie bancaire sector',
					description:
						'Anonimiseren van data in het gehele IT-landschap ten behoeve van de AVG, inclusief de optimalisatie van bestaande interfaces.',
					skills: ['SQL'],
				},
				{
					id: 'p9',
					title: 'Monitoring: Transacties in het grootboek',
					description:
						'Ontwikkeling van monitoring dashboard voor het detecteren van afwijkingen met behulp van machine learning.',
					skills: ['Power BI', 'SQL', 'Machine learning'],
				},
				{
					id: 'p10',
					title: 'Automatisering: HR Bestand',
					description:
						'Ontwikkeling van geautomatiseerde controle op uitdiensttreding van mensen in het onderwijs.',
					skills: ['Excel', 'VBA'],
				},
				{
					id: 'p11',
					title: 'Dashboarding: Managementinformatie MKB',
					description:
						'Bieden van inzichten met betrekking tot de financiële resultaten van een bedrijf in de retail sector aan de hand van audit files (XAF).',
					skills: ['Power BI', 'DAX', 'M'],
				},
			],
		},
		{
			id: 'a3',
			from: 'mei 2013',
			until: 'okt 2017',
			company: 'PwC',
			position: 'Operations Specialist',
			description:
				'Naast het beheren van het onderhanden werk betrokken bij de standaardisatie van productie rapportages alsmede de ontwikkeling van een gebruikersinterface in dit kader.',
			projects: [
				{
					id: 'p12',
					title: 'Automatisering: Productie rapportages',
					description:
						'Automatisch genereren van maandelijkse Excel rapportages inzake het openstaande onderhanden werk.',
					skills: ['Excel', 'VBA', 'SQL'],
				},
				{
					id: 'p13',
					title: 'Automatisering: Bulk aanmaken klanten',
					description:
						'Automatisch aanmaken van goedgekeurde nieuwe audit klanten in de online omgeving op basis van een Excel bestand.',
					skills: ['Excel', 'VBA'],
				},
				{
					id: 'p14',
					title: 'Reporting: Ondersteuning adviespraktijk',
					description:
						'Ad-hoc ondersteuning van de adviespraktijk in het kader van Excel rapportages.',
					skills: ['Excel', 'VBA'],
				},
			],
		},
		{
			id: 'a4',
			from: 'jul 2012',
			until: 'dec 2012',
			company: 'GE Capital',
			position: 'Tax stagair',
			description:
				'Betrokken bij de ondersteuning van het Tax Operations team tijdens de maandafsluiting, het uitvoeren van account reconciliaties, en het monitoren van de afdelingsbudgetten. Daarnaast verantwoordelijk voor het geven van cursussen over het gebruik van Excel en VBA, en deze kennis tevens ingezet om verscheidene bedrijfsprocessen te automatiseren.',
			projects: [
				{
					id: 'p15',
					title: 'Automatisering: Journaalposten verwerken',
					description:
						'Ontwikkeling van oplossing voor het automatisch verwerken van maandelijkse journaalposten op basis van Excel VBA.',
					skills: ['Excel', 'VBA'],
				},
				{
					id: 'p16',
					title: 'Automatisering: BTW terugvragen',
					description:
						'Automatisch opvoeren van goedgekeurde facturen bij Ierse belastingdienst om BTW terug te vragen.',
					skills: ['Excel', 'VBA'],
				},
			],
		},
	];

	const [filter, setFilter] = useState([]);
	const [selectedSkills, setSelectedSkilled] = useState([]);

	const filteredExp =
		selectedSkills?.length > 0
			? experiences
					.map((exp) => ({
						...exp,
						projects: exp.projects.filter((project) =>
							project.skills.some((r) => selectedSkills.includes(r))
						),
					}))
					.filter((exp) => exp.projects.length > 0)
			: experiences;

	useEffect(() => {
		setSelectedSkilled(filter?.map((x) => x.value));
	}, [filter]);

	return (
		<Container>
			<Header>
				<HeaderTitle>Business Analist</HeaderTitle>
				<HeaderSubtitle>drs. Marc Klootwijk</HeaderSubtitle>
			</Header>
			<Profile>
				<ProfileInner>
					<ProfileInformation>
						<ProfileTitle>Profiel</ProfileTitle>
						<ProfileText>
							In de afgelopen jaren heb ik mij gespecialiseerd in het
							digitaliseren van organisaties. Door mijn brede kennis van
							technologie en data-gedreven werken, ben ik in staat bedrijven te
							helpen bij het inzetten van data. Zo heb ik gewerkt aan het
							ontwikkelen van rapportages en dashboards, en het automatiseren en
							digitaliseren van processen. Daarnaast ben ik communicatief sterk
							en uitstekend in staat om het operationele te verbinden met IT.
						</ProfileText>
					</ProfileInformation>
					<ProfilePhoto>
						<ProfileImage img={'/photo_marcklootwijk.png'}></ProfileImage>
					</ProfilePhoto>
				</ProfileInner>
			</Profile>
			<SkillSelect>
				<SelectText>
					<SelectIcon />
					Zoek expertise
				</SelectText>
				<SelectBar
					closeMenuOnSelect={false}
					components={animatedComponents}
					isMulti
					options={skills}
					onChange={(event) => setFilter(event)}
					value={filter}
				/>
			</SkillSelect>

			<Experience>
				<FlipMove>
					{filteredExp.map((exp) => (
						<ActivityContainer>
							<ActivityCompany
								key={exp.id}
								startDate={exp.from}
								endDate={exp.until}
								company={exp.company}
								position={exp.position}
								description={exp.description}
							/>
							{exp.projects.map((project) => (
								<ActivityProject
									key={project.id}
									title={project.title}
									description={project.description}
									skills={project.skills}
								/>
							))}
						</ActivityContainer>
					))}
				</FlipMove>
			</Experience>
		</Container>
	);
}

export default ResumePage;
