import React, { useEffect, useState } from 'react';

import menuItems from '../../utils/menu';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

/* Style Components */
import styled from 'styled-components';

const Container = styled(BottomNavigation)`
	display: none;
	width: 100%;
	position: sticky;
	bottom: 0;
	background-color: ${(props) => props.theme.backgroundColor.primary};
	border-top: solid 1px ${(props) => props.theme.backgroundColor.tertiary};
	@media (max-width: ${(props) => props.theme.breakpoint.sm}) {
		display: flex;
	}
`;

const Item = styled(BottomNavigationAction)`
	transition: all 0.2s ease-in-out;
	color: ${(props) => props.theme.color.tertiary};

	&.active {
		color: ${(props) => props.theme.color.contrastLight};
	}

	& .MuiBottomNavigationAction-label {
		font-family: ${(props) => props.theme.fontFamily.primary};
		font-weight: ${(props) => props.theme.fontWeight.medium};
		font-size: ${(props) => props.theme.fontSize.mini};
	}
`;

export default function BottomNav({ forwardedRefs }) {
	const StringToAnchor = (label) => {
		switch (label) {
			case 'Transformatie':
				return forwardedRefs.transformation.current;
			case 'Digitalisering':
				return forwardedRefs.digital.current;
			case 'Automatisering':
				return forwardedRefs.automate.current;
			case 'Dashboarding':
				return forwardedRefs.dashboard.current;
			case 'Cover':
				return forwardedRefs.cover.current;
			default:
				return null;
		}
	};

	const handleClick = (target) => {
		if (target) {
			target.scrollIntoView({
				behavior: 'smooth',
				alignToTop: true,
			});
		}
	};

	useEffect(() => {
		document.addEventListener('scroll', () => {
			const scrollHeightDashboard =
				forwardedRefs.dashboard.current.scrollHeight;
			const scrollHeightAutomate = forwardedRefs.automate.current.scrollHeight;
			const scrollHeightDigital = forwardedRefs.digital.current.scrollHeight;
			const scrollHeightTransformation =
				forwardedRefs.transformation.current.scrollHeight;
			const scrollHeightCover = forwardedRefs.cover.current.scrollHeight;

			const scrollHeightTotal =
				scrollHeightCover +
				scrollHeightTransformation +
				scrollHeightDigital +
				scrollHeightAutomate +
				scrollHeightDashboard;

			const scrollCover = window.scrollY < scrollHeightCover;

			const scrollDashboard =
				window.scrollY > scrollHeightTotal - scrollHeightDashboard - 60;

			const scrollAutomate =
				window.scrollY >
				scrollHeightTotal - scrollHeightDashboard - scrollHeightAutomate - 60;

			const scrollDigital =
				window.scrollY >
				scrollHeightTotal -
					scrollHeightDashboard -
					scrollHeightAutomate -
					scrollHeightDigital -
					60;

			const scrollTransformation =
				window.scrollY >
				scrollHeightTotal -
					scrollHeightDashboard -
					scrollHeightAutomate -
					scrollHeightDigital -
					scrollHeightTransformation -
					60;

			if (scrollDashboard) {
				document.getElementById(5).classList.add('active');
				document.getElementById(4).classList.remove('active');
				document.getElementById(3).classList.remove('active');
				document.getElementById(2).classList.remove('active');
			} else if (scrollAutomate) {
				document.getElementById(4).classList.add('active');
				document.getElementById(5).classList.remove('active');
				document.getElementById(3).classList.remove('active');
				document.getElementById(2).classList.remove('active');
			} else if (scrollDigital) {
				document.getElementById(3).classList.add('active');
				document.getElementById(5).classList.remove('active');
				document.getElementById(4).classList.remove('active');
				document.getElementById(2).classList.remove('active');
			} else if (scrollTransformation && !scrollCover) {
				document.getElementById(2).classList.add('active');
				document.getElementById(5).classList.remove('active');
				document.getElementById(3).classList.remove('active');
				document.getElementById(4).classList.remove('active');
			} else if (scrollCover) {
				document.getElementById(2).classList.remove('active');
				document.getElementById(3).classList.remove('active');
				document.getElementById(4).classList.remove('active');
				document.getElementById(5).classList.remove('active');
			}
		});
	});

	return (
		<Container showLabels>
			{menuItems
				.filter((item) => item.bottom === true)
				.map((item) => (
					<Item
						id={item.id}
						onClick={(e) => handleClick(StringToAnchor(item.label))}
						key={item.id}
						label={item.label}
						icon={item.icon}
					/>
				))}
		</Container>
	);
}
