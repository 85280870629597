import React, { forwardRef } from 'react';

import Services from './Services';
import Typography from '@material-ui/core/Typography';

/* Style Components */
import styled from 'styled-components';

const Title = styled(Typography)`
	padding-bottom: 10px;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.huge};
`;

const Text = styled(Typography)`
	text-align: justify;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.regular};
`;

const ScrollFiller = styled.div`
	padding: 20px 0;
	background-color: ${(props) =>
		props.color === 'even'
			? props.theme.backgroundColor.secondary
			: props.theme.backgroundColor.secondaryClose};
`;

const ServicesDashboard = ({ img, align, color, fullWidth }, ref) => {
	return (
		<ScrollFiller ref={ref} color={color}>
			<Services img={img} align={align} color={color} fullWidth={fullWidth}>
				<Title>Dashboarding</Title>
				<Text>
					Bij veel bedrijven is het uitwisselen van Excel bestanden aan de orde
					van de dag. Ook wordt er nog veel tijd besteed aan het handmatig
					opstellen van rapportages. Door gebruik te maken van real-time
					dashboards en rapportages kunt u informatie gemakkelijk onderling
					uitwisselen of voldoen aan uw rapportageverplichtingen. Daarnaast kan
					dashboarding in combinatie met data-analyse waardevolle en nieuwe
					perspectieven bieden. Zo is er binnen uw organisatie ten alle tijden
					informatie beschikbaar om de juiste beslissingen te maken.
				</Text>
				<Text>
					<br />
				</Text>
				<Text>
					ESC Online voorziet ondernemingen van inzichten op basis van hun data
					in de vorm van dashboards en rapportages. Door waardevolle informatie
					te halen uit data creëren wij marktvoordeel voor uw onderneming.
				</Text>
			</Services>
		</ScrollFiller>
	);
};

const forwardServicesDashboard = forwardRef(ServicesDashboard);

export default forwardServicesDashboard;
