import React, { forwardRef } from 'react';

import Services from './Services';
import Typography from '@material-ui/core/Typography';

/* Style Components */
import styled from 'styled-components';

const Title = styled(Typography)`
	padding-bottom: 10px;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.huge};
`;

const Text = styled(Typography)`
	text-align: justify;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.regular};
`;

const ScrollFiller = styled.div`
	padding: 20px 0;
	background-color: ${(props) =>
		props.color === 'even'
			? props.theme.backgroundColor.secondary
			: props.theme.backgroundColor.secondaryClose};
`;

const ServicesAutomate = ({ img, align, color, fullWidth }, ref) => {
	return (
		<ScrollFiller ref={ref} color={color}>
			<Services img={img} align={align} color={color} fullWidth={fullWidth}>
				<Title>Automatisering</Title>
				<Text>
					Bedrijven voeren nog steeds veel backoffice werkzaamheden handmatig
					uit. Medewerkers ervaren deze werkzaamheden vaak als administratieve
					last. Gegevens kopiëren van en naar systemen, of iedere dag hetzelfde
					overzicht aanvullen, is tijdrovend en repeterend werk. Dit gaat ten
					koste van de medewerkerstevredenheid en de financiële middelen van uw
					onderneming. Daarnaast is gebleken dat tijdens het uitvoeren van
					repeterend werk meer fouten worden gemaakt.
				</Text>
				<Text>
					<br />
				</Text>
				<Text>
					Voor handelingen waarbij meerdere systemen en programma’s gebruikt
					worden kan <i>Robotic Process Automation (RPA)</i> worden ingezet. Dit
					zijn virtuele robots die het werk van mensen uit handen nemen. Deze
					robots zijn 24 uur per dag beschikbaar en hun voortgang is te volgen
					in een online omgeving. In andere situaties volstaat het ontwikkelen
					van een enkele procedure. Voorbeelden hiervan zijn Excel macro's (VBA)
					en overige oplossingen geschreven in programmeertalen zoals Python en
					R.
				</Text>
				<Text>
					<br />
				</Text>
				<Text>
					ESC Online ontwikkelt en implementeert automatiseringsoplossingen
					waardoor medewerkers zich kunnen concentreren op hun kernactiviteiten.
					Door bedrijfsprocessen te automatiseren kunnen repeterende en
					arbeidsintensieve taken uit handen van medewerkers worden genomen en
					voorkomt u fouten.
				</Text>
			</Services>
		</ScrollFiller>
	);
};

const forwardServicesAutomate = forwardRef(ServicesAutomate);

export default forwardServicesAutomate;
