import React, { forwardRef } from 'react';

import Services from './Services';
import Typography from '@material-ui/core/Typography';

/* Style Components */
import styled from 'styled-components';

const Title = styled(Typography)`
	padding-bottom: 10px;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-size: ${(props) => props.theme.fontSize.huge};
`;

const Text = styled(Typography)`
	text-align: justify;
	color: ${(props) => props.theme.color.primary};
	font-family: ${(props) => props.theme.fontFamily.primary};
	font-weight: ${(props) => props.theme.fontWeight.medium};
	font-size: ${(props) => props.theme.fontSize.regular};
`;

const ScrollFiller = styled.div`
	padding: 20px 0;
	background-color: ${(props) =>
		props.color === 'even'
			? props.theme.backgroundColor.secondary
			: props.theme.backgroundColor.secondaryClose};
`;

const ServicesDigital = ({ img, align, color, fullWidth }, ref) => {
	return (
		<ScrollFiller ref={ref} color={color}>
			<Services img={img} align={align} color={color} fullWidth={fullWidth}>
				<Title>Digitalisering</Title>
				<Text>
					Het digitaliseren van uw organisatie begint bij het vervangen van
					stapels papier en fysieke handelingen door digitale equivalenten. Dit
					stelt uw onderneming in staat om de eerste stap te zetten in uw
					digitale transformatie. Bijvoorbeeld door de ontwikkeling van een
					online omgeving om de communicatie met externe partijen te
					structureren. Hierdoor kunnen vervolg stappen in het bedrijfsproces
					geautomatiseerd worden en bouwt u aan een rijke datavoorziening.
				</Text>
				<Text>
					<br />
				</Text>
				<Text>
					ESC Online ontwikkelt portalen en platforms om activiteiten te
					digitaliseren en data te structureren. Door gebruik te maken van de
					nieuwste technologieën zijn wij in staat de online omgevingen naadloos
					aan te laten sluiten bij uw huidige IT-infrastructuur. Hiermee legt u
					de fundering voor automatisering van processen en het effectief
					uitwisselen van informatie binnen uw organisatie.
				</Text>
			</Services>
		</ScrollFiller>
	);
};

const forwardServicesDigital = forwardRef(ServicesDigital);

export default forwardServicesDigital;
